import { reducerWithInitialState } from "typescript-fsa-reducers"

import { store } from "@/types/interface"
import { loadAction, getKidsTypesAction } from "../actions/course-type"

const initialState: store.CourseType = {
  courseType: null,
  courseTypes: [],
}

const reducer = reducerWithInitialState(initialState)
  .case(loadAction.started, (state) => ({
    ...state,
  }))
  .case(loadAction.done, (state, data) => ({
    ...state,
    courseTypes: data.result.courseTypes,
  }))
  .case(loadAction.failed, (state, data) => ({
    ...state,
    error: data.error,
  }))
  .case(getKidsTypesAction.started, (state) => ({
    ...state,
  }))
  .case(getKidsTypesAction.done, (state, data) => ({
    ...state,
    courseTypes: data.result.courseTypes,
  }))
  .case(getKidsTypesAction.failed, (state, data) => ({
    ...state,
    error: data.error,
  }))

export default reducer
