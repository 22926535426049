import {
  combineReducers,
  createStore as reduxCreateStore,
  Store,
  applyMiddleware,
  compose,
} from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import axios from "axios"

import {
  courseType,
  course,
  news,
  excursionLesson,
  siteInformation,
  specialCourse,
  loading,
  error,
} from "../reducers"

const client = axios.create({ baseURL: process.env.SERVER })
const thunkWithClient = thunk.withExtraArgument(client)

const reducer = combineReducers({
  courseType,
  course,
  news,
  excursionLesson,
  siteInformation,
  specialCourse,
  loading,
  error,
})

const composeEnhancer: typeof composeWithDevTools =
  process.env.NODE_ENV === "development" ? composeWithDevTools : compose

const initialData = {}
const createStore = (): Store =>
  reduxCreateStore(
    reducer,
    initialData,
    composeEnhancer(applyMiddleware(thunkWithClient))
  )

export default createStore
