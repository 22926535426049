import actionCreatorFactory from "typescript-fsa"
import { AxiosInstance } from "axios"
import { Store } from "redux"

import { route } from "@/types/interface"

const actionCreator = actionCreatorFactory()

export const loadAction = actionCreator.async<
  {},
  { news: route.News[] },
  { error: Error }
>("news/LOAD")

export function load() {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    const query = { sort: '-date' }
    dispatch(loadAction.started({}))
    return client
      .get("/news", { params: { query }})
      .then(res => res.data.data)
      .then(news => {
        dispatch(
          loadAction.done({
            params: {},
            result: { news },
          })
        )
      })
      .catch(error => {
        dispatch(loadAction.failed({ params: {}, error }))
      })
  }
}
