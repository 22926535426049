import { reducerWithInitialState } from "typescript-fsa-reducers"

import { store } from "@/types/interface"
import { loadAction, findAction } from "../actions/excursion-lesson"

const initialState: store.ExcursionLesson = {
  excursionLesson: null,
  excursionLessons: [],
}

const reducer = reducerWithInitialState(initialState)
  .case(loadAction.started, (state) => ({
    ...state,
  }))
  .case(loadAction.done, (state, data) => ({
    ...state,
    excursionLessons: data.result.excursionLessons,
  }))
  .case(loadAction.failed, (state, data) => ({
    ...state,
    error: data.error,
  }))
  .case(findAction.started, (state) => ({
    ...state,
  }))
  .case(findAction.done, (state, data) => ({
    ...state,
    excursionLesson: data.result.excursionLesson,
  }))
  .case(findAction.failed, (state, data) => ({
    ...state,
    error: data.error
  }))

export default reducer
