import actionCreatorFactory from "typescript-fsa"
import { AxiosInstance } from "axios"
import { Store } from "redux"

import { route } from "@/types/interface"

const actionCreator = actionCreatorFactory()

export const getSpecialCourseAction = actionCreator.async<
  {},
  { specialCourse: route.SpecialCourse },
  { error: Error }
>("special-course/GET")

export function getSpecialCourse() {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    dispatch(getSpecialCourseAction.started({}))
    return client
      .get("special-courses")
      .then(res => res.data)
      .then(result => {
        dispatch(
          getSpecialCourseAction.done({
            params: {},
            result: { specialCourse: result.data[0] },
          })
        )
      })
      .catch(error => {
        dispatch(getSpecialCourseAction.failed({ params: {}, error }))
      })
  }
}
