const loadingReducer = (state = {}, action) => {
  const { type } = action
  const matches = /(.*)_(DONE|FAILED|STARTED)/.exec(type)

  // not a *_DONE / *_FAILED / *_STARTED actions, so we ignore them
  if (!matches) return state

  const [, requestName, requestState] = matches
  // stop loading when failed happen
  if (requestState === 'FAILED') return {}
  return {
    ...state,
    [requestName]: requestState !== 'DONE'
  }

}

export default loadingReducer
