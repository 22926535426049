import { navigate } from "gatsby"

import { submitAction } from "../actions/application"

const errorReducer = (state = {}, action) => {
  const { type, payload } = action
  const matches = /(.*)_(FAILED)/.exec(type)

  if (!matches || type.match(submitAction.type)) return state
  // if api call failed, navigate to not found page
  navigate("/404")

  const [, requestName, requestState] = matches
  return {
    ...state,
    [requestName]: requestState === 'FAILED' ? payload.error.message : ""
  }
}

export default errorReducer
