import { reducerWithInitialState } from "typescript-fsa-reducers"

import { store } from "@/types/interface"
import { getSpecialCourseAction } from "../actions/special-course"

const initialState: store.SpecialCourse = {
  specialCourse: null,
}

const reducer = reducerWithInitialState(initialState).case(
  getSpecialCourseAction.done,
  (state, data) => ({
    ...state,
    specialCourse: data.result.specialCourse,
  })
)

export default reducer
