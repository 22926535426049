import actionCreatorFactory from "typescript-fsa"
import { AxiosInstance } from "axios"
import { Store } from "redux"

import { route } from "@/types/interface"

const actionCreator = actionCreatorFactory()

export const submitAction = actionCreator.async<
  { application: route.Application },
  { application: route.Application },
  { error: Error }
>("application/SUBMIT")

export function submit(application: route.Application) {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    dispatch(submitAction.started({ application }))
    return client
      .post("/applications", application, {
        headers: { "Content-Type": "application/json"}
      })
      .then(res => res.data)
      .then(application => {
        dispatch(
          submitAction.done({
            params: { application },
            result: { application },
          })
        )
      }).catch(error => {
        dispatch(submitAction.failed({ params: { application }, error}))
        throw new Error(error.msg)
      })
  }
}
