import actionCreatorFactory from "typescript-fsa"
import { AxiosInstance } from "axios"
import { Store } from "redux"

import { route } from "@/types/interface"

const actionCreator = actionCreatorFactory()

// filter courses action by course-type
// define types of <params, result, error> for action by typescript-fsa
export const filterAction = actionCreator.async<
  { courseTypeId: string },
  { courseAbstracts: route.CourseAbstract[] },
  { error: Error }
>("course/FILTER")

export function filter(courseTypeId: string) {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    dispatch(filterAction.started({ courseTypeId }))
    return client
      .get("/courses/types/" + courseTypeId)
      .then(res => res.data)
      .then(courseAbstracts => {
        dispatch(
          filterAction.done({
            params: { courseTypeId },
            result: { courseAbstracts },
          })
        )
      })
      .catch(error => {
        dispatch(filterAction.failed({ params: { courseTypeId }, error }))
      })
  }
}

// get course detail by course id
export const findAction = actionCreator.async<
  { courseId: string },
  { courseDetail: route.CourseDetail },
  { error: Error }
>("course/FIND")

export function find(courseId: string) {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    dispatch(findAction.started({ courseId }))
    return client
      .get("/courses/regular/" + courseId)
      .then(res => res.data)
      .then(courseDetail => {
        dispatch(
          findAction.done({
            params: { courseId },
            result: { courseDetail },
          })
        )
      })
      .catch(error => {
        dispatch(findAction.failed({ params: { courseId }, error }))
      })
  }
}

// get new courses
export const getNewCoursesAction = actionCreator.async<
  {},
  { courseAbstracts: route.CourseAbstract[] },
  { error: Error }
>("course/GET_NEW_COURSES")

export function getNewCourses() {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    dispatch(getNewCoursesAction.started({}))
    return client
      .get("/courses/new")
      .then(res => res.data)
      .then(courseAbstracts => {
        dispatch(
          getNewCoursesAction.done({
            params: {},
            result: { courseAbstracts },
          })
        )
      })
      .catch(error => {
        dispatch(getNewCoursesAction.failed({ params: {}, error }))
      })
  }
}

// filter courses by multiple courseTypes
export const filterCoursesByMultiTypesAction = actionCreator.async<
  { courseTypeIds: string[] },
  { courseAbstracts: route.CourseAbstract[] },
  { error: Error }
>("course/FILTER_COURSES_BY_MULTI_TYPES")

export function filterCoursesByMultiTypes(courseTypeIds: string[]) {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    dispatch(filterCoursesByMultiTypesAction.started({ courseTypeIds }))
    // parallel request to get courseAbstracts
    Promise.all(
      courseTypeIds.map(async courseTypeId => {
        const courseAbstracts = await client
          .get("/courses/types/" + courseTypeId)
          .then(res => res.data)
        return courseAbstracts
      })
    )
      .then(courseAbstractsSet =>
        // convert array of array into just array
        courseAbstractsSet.reduce((pre, current) => {
          pre.push(...current)
          return pre
        }, [])
      )
      .then(courseAbstracts =>
        dispatch(
          filterCoursesByMultiTypesAction.done({
            params: { courseTypeIds },
            result: { courseAbstracts },
          })
        )
      )
      .catch(error => {
        dispatch(
          filterCoursesByMultiTypesAction.failed({
            params: { courseTypeIds },
            error,
          })
        )
      })
  }
}
