import { reducerWithInitialState } from "typescript-fsa-reducers"

import { store } from "@/types/interface"
import { loadAction } from "../actions/news"

const initialState: store.News = {
  news: [],
}

const reducer = reducerWithInitialState(initialState)
  .case(loadAction.started, (state) => ({
    ...state
  }))
  .case(loadAction.done, (state, data) => ({
    ...state,
    news: data.result.news,
  }))
  .case(loadAction.failed, (state, data) => ({
    ...state,
    error: data.error,
  }))

export default reducer
  