import { reducerWithInitialState } from "typescript-fsa-reducers"

import { store } from "@/types/interface"
import { getSiteInformationAction } from "../actions/site-information"

const initialState: store.SiteInformation = {
  siteInformation: null,
}

const reducer = reducerWithInitialState(initialState).case(
  getSiteInformationAction.done,
  (state, data) => ({
    ...state,
    siteInformation: data.result.siteInformation,
  })
)

export default reducer
