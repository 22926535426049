import { reducerWithInitialState } from "typescript-fsa-reducers"

import { store } from "@/types/interface"
import {
  filterAction,
  findAction,
  getNewCoursesAction,
  filterCoursesByMultiTypesAction,
} from "../actions/course"

const initialState: store.Course = {
  courseAbstracts: [],
  courseDetail: null,
}

const reducer = reducerWithInitialState(initialState)
  .case(filterAction.started, (state) => ({
    ...state,
  }))
  .case(filterAction.done, (state, data) => ({
    ...state,
    courseAbstracts: data.result.courseAbstracts.reverse(),
  }))
  .case(filterAction.failed, (state, data) => ({
    ...state,
    error: data.error,
  }))
  .case(findAction.started, (state) => ({
    ...state,
  }))
  .case(findAction.done, (state, data) => ({
    ...state,
    courseDetail: data.result.courseDetail,
  }))
  .case(findAction.failed, (state, data) => ({
    ...state,
    error: data.error,
  }))
  .case(getNewCoursesAction.started, (state) => ({
    ...state,
  }))
  .case(getNewCoursesAction.done, (state, data) => ({
    ...state,
    courseAbstracts: data.result.courseAbstracts,
  }))
  .case(getNewCoursesAction.failed, (state, data) => ({
    ...state,
    error: data.error,
  }))
  .case(filterCoursesByMultiTypesAction.started, (state) => ({
    ...state,
  }))
  .case(filterCoursesByMultiTypesAction.done, (state, data) => ({
    ...state,
    courseAbstracts: data.result.courseAbstracts.reverse(),
  }))
  .case(filterCoursesByMultiTypesAction.failed, (state, data) => ({
    ...state,
    error: data.error
  }))

export default reducer
