import actionCreatorFactory from "typescript-fsa"
import { AxiosInstance } from "axios"
import { Store } from "redux"

import { route } from "@/types/interface"

const actionCreator = actionCreatorFactory()

export const loadAction = actionCreator.async<
  {},
  { excursionLessons: route.ExcursionLesson[] },
  { error: Error }
>("excursionLesson/LOAD")

export function load() {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    const query = { limit: 50 }
    dispatch(loadAction.started({}))
    return client
      .get("/excursion-lessons", { params: { query }})
      .then(res => res.data.data)
      .then(excursionLessons => {
        dispatch(
          loadAction.done({
            params: {},
            result: { excursionLessons },
          })
        )
      })
      .catch(error => {
        dispatch(loadAction.failed({ params: {}, error }))
      })
  }
}

export const findAction = actionCreator.async<
  { excursionLessonId: string },
  { excursionLesson: route.ExcursionLesson },
  { error: Error }
>("excursionLesson/FIND")

export function find(excursionLessonId: string) {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    dispatch(findAction.started({ excursionLessonId }))
    return client
      .get("/excursion-lessons/" + excursionLessonId)
      .then(res => res.data)
      .then(excursionLesson => {
        dispatch(
          findAction.done({
            params: { excursionLessonId },
            result: { excursionLesson },
          })
        )
      })
      .catch(error => {
        dispatch(findAction.failed({ params: { excursionLessonId }, error }))
      })
  }
}
