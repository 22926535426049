// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-course-type-index-tsx": () => import("./../src/templates/course-type/index.tsx" /* webpackChunkName: "component---src-templates-course-type-index-tsx" */),
  "component---src-templates-course-detail-index-tsx": () => import("./../src/templates/course-detail/index.tsx" /* webpackChunkName: "component---src-templates-course-detail-index-tsx" */),
  "component---src-templates-excursion-detail-index-tsx": () => import("./../src/templates/excursion-detail/index.tsx" /* webpackChunkName: "component---src-templates-excursion-detail-index-tsx" */),
  "component---src-templates-form-index-tsx": () => import("./../src/templates/form/index.tsx" /* webpackChunkName: "component---src-templates-form-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-excursion-tsx": () => import("./../src/pages/excursion.tsx" /* webpackChunkName: "component---src-pages-excursion-tsx" */),
  "component---src-pages-flyer-tsx": () => import("./../src/pages/flyer.tsx" /* webpackChunkName: "component---src-pages-flyer-tsx" */),
  "component---src-pages-global-business-tsx": () => import("./../src/pages/global-business.tsx" /* webpackChunkName: "component---src-pages-global-business-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kids-tsx": () => import("./../src/pages/kids.tsx" /* webpackChunkName: "component---src-pages-kids-tsx" */),
  "component---src-pages-new-tsx": () => import("./../src/pages/new.tsx" /* webpackChunkName: "component---src-pages-new-tsx" */),
  "component---src-pages-online-guide-tsx": () => import("./../src/pages/online-guide.tsx" /* webpackChunkName: "component---src-pages-online-guide-tsx" */)
}

