import actionCreatorFactory from "typescript-fsa"
import { AxiosInstance } from "axios"
import { Store } from "redux"

import { route } from "@/types/interface"

const actionCreator = actionCreatorFactory()

export const getSiteInformationAction = actionCreator.async<
  {},
  { siteInformation: route.SiteInformation },
  { error: Error }
>("site-information/GET")

export function getSiteInformation() {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    dispatch(getSiteInformationAction.started({}))
    return client
      .get("site-information")
      .then(res => res.data)
      .then(result => {
        dispatch(
          getSiteInformationAction.done({
            params: {},
            result: { siteInformation: result.data[0] },
          })
        )
      })
      .catch(error => {
        dispatch(getSiteInformationAction.failed({ params: {}, error }))
      })
  }
}
