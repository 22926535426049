import actionCreatorFactory from "typescript-fsa"
import { AxiosInstance } from "axios"
import { Store } from "redux"

import { route } from "@/types/interface"

const actionCreator = actionCreatorFactory()

// define types of <params, result, error> for action by typescript-fsa
export const loadAction = actionCreator.async<
  {},
  { courseTypes: route.CourseType[] },
  { error: Error }
>("courseType/LOAD")

export function load() {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    const query = { where: { forKids: false }, limit: 50 }
    dispatch(loadAction.started({}))
    return client
      .get("/course-types", { params: { query }})
      .then(res => res.data.data)
      .then(courseTypes => {
        dispatch(
          loadAction.done({
            params: {},
            result: { courseTypes },
          })
        )
      })
      .catch(error => {
        dispatch(loadAction.failed({ params: {}, error }))
      })
  }
}

// get courseTypes for kids
export const getKidsTypesAction = actionCreator.async<
  {},
  { courseTypes: route.CourseType[] },
  { error: Error }
>("courseType/GET_KIDS_TYPES")

export function getKidsTypes() {
  return async (
    dispatch: Store["dispatch"],
    getState: Store["getState"],
    client: AxiosInstance
  ): Promise<void> => {
    const query = { where: { forKids: true }, limit: 50 }
    dispatch(getKidsTypesAction.started({}))
    return client
      .get("/course-types", { params: { query } })
      .then(res => res.data.data)
      .then(courseTypes => {
        dispatch(
          getKidsTypesAction.done({
            params: {},
            result: { courseTypes },
          })
        )
      })
      .catch(error => {
        dispatch(getKidsTypesAction.failed({ params: {}, error }))
      })
  }
}
